import * as React from 'react';
import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { ILanguageListField, ILanguageListProps } from './LanguageSelector.type';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store/store';
import { selectLanguage } from '../../../features/selectedLanguageSlice';
import { StNextIcon } from '../../../components/shared/NextImage.styles';
import { getCookieExpiryTime } from '../../../lib/utils/enrollmentUtil';
import { getQueryParams } from '../../../lib/utils/common';
import { getCookie, setCookie } from 'cookies-next';
import CaretDown from '../../../../public/caret-down.webp';
import CaretUp from '../../../../public/caret-up.webp';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const fallBackLanguage: ILanguageListField = {
  languageCode: 'en',
  displayName: 'English',
  nativeName: 'English',
  flagIcon: '',
  countryName: 'united states',
  countryISO2: 'US',
  countryISO3: 'USA',
};

export const isLocaleInLangList = (langList: ILanguageListField[], locale: string): boolean => {
  if (Array.isArray(langList)) {
    const lang = langList.find((item) => item.languageCode === locale);
    return lang ? true : false;
  }
  return false;
};
export const getLanguage = (langList: ILanguageListField[], locale: string): ILanguageListField => {
  if (Array.isArray(langList)) {
    const enLang = langList.find((item) => item.languageCode === 'en');
    const language = langList.find((item) => item.languageCode === locale);
    return language ? language : enLang ? enLang : fallBackLanguage;
  }
  return fallBackLanguage;
};

export default function LanguageSelector(props: ILanguageListProps): JSX.Element {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [caretStatus, setCaretStatus] = useState(true);
  const selectedLanguage = useSelector((state: RootState) => state.selectedLanguage);
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { uniqueId, isHomePage } = props;
  const { sitecoreContext } = useSitecoreContext();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down(768));
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );

  const desiredLanguage = React.useMemo(() => {
    const routerLocale = router?.locale || '';
    if (isLocaleInLangList(props.languageList, routerLocale)) {
      return getLanguage(props.languageList, routerLocale);
    }
    return fallBackLanguage;
  }, [props.languageList, router.locale, fallBackLanguage]);

  React.useEffect(() => {
    if (selectedLanguage.languageCode !== desiredLanguage.languageCode) {
      dispatch(selectLanguage(desiredLanguage));
    }
  }, [desiredLanguage, selectedLanguage.languageCode, dispatch]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setCaretStatus(!caretStatus);
  };

  const resetCookies = () => {
    setCookie('enrollmentID', null, {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyStatus', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyStep', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyType', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    if (!isAuthenticated) {
      setCookie('bx_guest_ref', '', {
        expires: getCookieExpiryTime(0),
        secure: true,
      });
      const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
      setCookie(cookieName, '', {
        secure: true,
        expires: getCookieExpiryTime(0),
      });
    }
  };

  const handleClose = async (event: Event | React.SyntheticEvent, language: any) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return;
    }
    dispatch(selectLanguage(language));
    setOpen(false);
    setCaretStatus(true);
    const locale = language.languageCode || 'en';

    const cookie_expiry = new Date();
    cookie_expiry.setDate(cookie_expiry.getDate() + 10e5);
    setCookie('language', locale, { expires: cookie_expiry });

    if (sitecoreContext?.site?.name === 'ClinicalResearch') {
      const updateLanguageDropdown = (selectedLanguage: string) => {
        const { query } = router;
        const queryData = getQueryParams(query);
        resetCookies();
        const domain = window?.location?.origin;
        const path = window?.location?.pathname;
        const hash = window?.location?.hash;
        const nP = selectedLanguage !== 'en' ? path.replace('/en/', '/') : path;
        // Remove any existing language prefix from the path. Below Regex will match language tags in path. Example /fr-FR/
        const newPath = nP.replace(/^\/[a-zA-Z]{2}-[a-zA-Z]{2}\//, '/');
        if (queryData === '') {
          const newUrl = domain + '/' + selectedLanguage + newPath + hash;
          window.location.href = newUrl;
        } else {
          const newUrl = domain + '/' + selectedLanguage + newPath + hash + `?${queryData}`;
          window.location.href = newUrl;
        }
      };
      //TODO:geoIP
      const pathNameArray = (getCookie('epr-name') as string)?.split('/');
      const eprNameFromPathname = pathNameArray[pathNameArray.length - 1];
      const taEprLanguage: any = {};
      taEprLanguage[eprNameFromPathname] = locale;
      setCookie('taEprLanguage', JSON.stringify(taEprLanguage), {
        expires: cookie_expiry,
        secure: true,
      });

      updateLanguageDropdown(locale);
    } else {
      const { query, defaultLocale } = router;
      resetCookies();
      const queryData = getQueryParams(query);
      let hrefValue: string;
      if (queryData === '') {
        hrefValue = locale === defaultLocale ? `/` : `/${locale}`;
      } else {
        hrefValue = locale === defaultLocale ? `/?${queryData}` : `/${locale}?${queryData}`;
      }
      window.location.href = hrefValue;
    }
  };

  const closeAway = () => {
    setOpen(false);
    setCaretStatus(true);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box
      display="flex"
      sx={{
        marginInline: props.isSubHeader ? `${isNotDesktop ? '0' : '3rem 0'}` : '1rem',
        alignItems: props.isSubHeader ? 'end' : 'center',
      }}
    >
      <Button
        ref={anchorRef}
        id={`${uniqueId}composition-button`}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        data-testid={props?.languageList && props?.languageList[0]?.displayName}
        sx={{
          color: props?.languageDDColor || theme.palette.themeColor.main,
          padding: 0,
          display: 'flex',
        }}
        disabled={isHomePage ? false : true}
      >
        <Image
          alt={`${selectedLanguage?.nativeName} flag icon`}
          src={selectedLanguage?.flagIcon || ''}
          height={13}
          width={19}
        />
        <span
          //todo: fix style with theme later
          style={{
            textTransform: 'capitalize',
            lineHeight: 1,
            fontWeight: 500,
            fontSize: '1rem',
            paddingInline: '0.5rem',
          }}
        >
          {isMobileDevice && props.isSubHeader
            ? selectedLanguage?.nativeName
            : isMobile
            ? String(selectedLanguage?.languageCode || '').toUpperCase()
            : selectedLanguage?.nativeName}
        </span>

        {caretStatus ? (
          <StNextIcon
            suppressHydrationWarning
            alt="expand-all"
            src={CaretUp}
            width={24}
            height={24}
            overrideIconColor={
              isHomePage
                ? props?.languageDDColor || theme.palette.themeColor.main
                : theme.palette.neutral4.main
            }
          />
        ) : (
          <StNextIcon
            suppressHydrationWarning
            alt="expand-all"
            src={CaretDown}
            width={24}
            height={24}
            overrideIconColor={
              isHomePage
                ? props?.languageDDColor || theme.palette.themeColor.main
                : theme.palette.neutral4.main
            }
          />
        )}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef?.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeAway}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby={`${uniqueId}composition-button`}
                  onKeyDown={handleListKeyDown}
                  sx={{ padding: 0, height: 'auto', overflow: 'auto' }}
                >
                  {props?.languageList &&
                    props?.languageList.map((language: any) => {
                      return (
                        <MenuItem
                          key={language?.languageCode}
                          onClick={(e) => handleClose(e, language)}
                          sx={{
                            color: props?.languageDDColor || theme.palette.themeColor.main,
                            padding: '16px',
                          }}
                        >
                          <Image
                            alt={language?.nativeName}
                            src={language?.flagIcon || ''}
                            height={13}
                            width={19}
                          />
                          <span
                            style={{
                              paddingLeft: '8px',
                              lineHeight: 1,
                              fontSize: '1rem',
                              fontWeight: 600,
                            }}
                          >
                            {language?.nativeName}
                          </span>
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
